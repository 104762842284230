import React, { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useRollbarPerson } from '@rollbar/react';
import useAccessToken from 'hooks/useAccessToken';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

import { checkIfTokenIsValid } from '../authStorage';
import routes, { Route as RouteType } from './routes';

const getValidRedirectUrl = (redirectUrl: string) => {
  // the redirectUrl could be something like: /redirect-path&param=true for this url to work and keep the params
  // the first & needs to be replaced with ?
  const redirectUrlPath = redirectUrl.split('?')[0].split('&')[0];
  const validRedirectUrl = Object.values(routes).some((routeValue) => routeValue.path === redirectUrlPath);
  return validRedirectUrl ? redirectUrl.replace('&', '?') : '/';
};

interface Props {
  route: RouteType;
}

const AuthRoute: FC<Props> = ({ route }) => {
  const {
    component: Component,
    path,
    private: privateRoute,
    isFinancialAdvisorExclusive,
    isNonFinancialAdvisorExclusive,
  } = route;
  const [getUser, user] = useLazyGetUserInformationQuery();
  const token = useAccessToken();
  const tokenIsValid = checkIfTokenIsValid(token);

  useRollbarPerson({
    id: user?.data?.id || '',
    email: user?.data?.email || '',
    rut: user?.data?.rut || '',
    username: user.data ? `${user.data.firstName} ${user.data.lastName}` : '',
    firstName: user?.data?.firstName || '',
    lastName: user?.data?.lastName || '',
  });

  useEffect(() => {
    if (tokenIsValid) {
      getUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [tokenIsValid]);

  if (tokenIsValid && (path === '/sign-in' || path === '/sign-up')) {
    // Adds the text after '?redirect=/'
    if (window.location.search.includes('referrer=')) {
      return <Navigate to="/" />;
    }
    const redirectUrl = `/${window.location.search.slice(11)}`;
    return <Navigate to={getValidRedirectUrl(redirectUrl)} />;
  }
  if (privateRoute && !tokenIsValid && path === '/') {
    return <Navigate to="/welcome" />;
  }
  if (privateRoute && !tokenIsValid) {
    const signInPath = `/sign-in?redirect=${window.location.pathname}${window.location.search}`;
    return <Navigate to={signInPath} />;
  }
  if (path === '/admin' && user.data && !user.data.isAdmin) {
    return <Navigate to="/unauthorized" />;
  }
  if (isFinancialAdvisorExclusive && user.data && !user.data.isFinancialAdvisorUser) {
    return <Navigate to="/" />;
  }
  if (isNonFinancialAdvisorExclusive && user.data && user.data.isFinancialAdvisorUser) {
    return <Navigate to="/chat" />;
  }
  return <Component />;
};

export default AuthRoute;
