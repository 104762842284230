import { FinancialStatus } from '../types/financialStatus';
import baseApi from './baseApi';

const financialStatusAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFinancialStatus: build.mutation({
      query: () => ({
        url: `/financialStatuses/last`,
        method: 'GET',
      }),
      transformResponse: (response: FinancialStatus): FinancialStatus => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetFinancialStatusMutation } = financialStatusAPI;
