import { Translation } from './interface';

const portugueseTranslation: Translation = {
  id: 'pt',
  landingPage: {
    header: {
      title: 'Melhore seus créditos e organize suas finanças',
      subtitle1: 'Somos a plataforma onde os colaboradores das empresas melhoram sua situação financeira.',
      subtitle2: 'Seu empregador nos contrata, nós te ajudamos.',
      knowMoreButton: 'Conhecer mais',
    },
    navigation: {
      featuresButton: 'Serviços',
      companiesButton: 'Empregadores',
      teamButton: 'Equipe',
      contactButton: 'Contate-nos',
      hello: 'Olá',
      dashboardButton: 'Meu painel',
      signUpButton: 'Registrar-se',
      signInButton: 'Iniciar sessão',
      blog: 'Blog',
    },
    features: {
      signUpButton: 'Registre-se',
      workCafeFeature1: {
        title: 'Acesse seu relatório comercial',
        description: 'Verifique quanto você deve e se está atrasado com suas dívidas',
      },
      feature1: {
        title: 'Melhoramos as condições de suas dívidas vencidas',
        description:
          'Miguelina verificou seu relatório comercial gratuitamente e encontrou um desconto de 70% em sua dívida vencida',
      },
      feature2: {
        title: 'Melhoramos as condições de seus créditos em dia',
        description:
          'Luis reduziu a parcela de sua dívida de CLP$200.000 para CLP$62.000 sem aumentar o valor total a ser pago',
      },
      feature3: {
        title: 'Organize seu dinheiro',
        description:
          'Pablo fez seu orçamento, agora chega ao fim do mês com tranquilidade e economiza para a educação de seu filho',
      },
      question: 'O que você pode fazer com',
    },
    steps: {
      title: 'Tudo isso em 3 etapas simples',
      step1: 'Registre-se e complete seus dados em 2 minutos',
      step2: 'Analisamos suas informações',
      step3: 'Entregamos as melhores soluções',
    },
    company: {
      title: 'Mostre esta seção ao seu empregador',
      subtitle1: 'Dê aos seus colaboradores o melhor benefício que eles podem ter,',
      boldSubtitle: 'saúde financeira',
      subtitle2: '. Assim você obterá:',
      benefit1: 'Colaboradores mais produtivos',
      benefit2: 'Menor absenteísmo',
      benefit3: 'Maior qualidade de vida na empresa',
      benefit4: 'Menor rotatividade',
      benefit5: 'Junte-se às empresas que já estão usando o Relif',
      title2: 'Algumas das empresas que já estão usando o Relif',
    },
    team: {
      title1: 'Equipe de',
      specialTitle: 'Relif',
      title2: '',
      jorge: {
        job: 'Co-fundador & CTO',
        prevJob: [
          {
            place: 'Meta',
            title: 'Software Engineer',
          },
          {
            place: 'PUC',
            title: 'Engenheiro Civil Computação',
          },
        ],
      },
      benja: {
        job: 'Co-fundador & CEO',
        prevJob: [
          {
            place: 'London Business School',
            title: 'MBA',
          },
          { place: 'Credicorp Capital', title: 'Investment Banking Associate' },
        ],
      },
      carlos: {
        job: 'Co-fundador & COO',
        prevJob: [
          {
            place: 'London Business School',
            title: 'MBA',
          },
          { place: 'Banco Bice', title: 'Wealth Management Advisor' },
        ],
      },
      juan: {
        job: 'Desenvolvedor',
        prevJob: [
          { place: 'PUC', title: 'Engenheiro Industrial TI' },
          { place: 'Forcast', title: 'Desenvolvedor Full Stack' },
        ],
      },
      tomas: {
        job: 'Desenvolvedor',
        prevJob: [
          { place: 'PUC', title: 'Engenheiro Civil Computação' },
          { place: 'Celcom', title: 'Desenvolvedor Full Stack' },
        ],
      },
      trinidad: {
        job: 'Key Account Manager',
        prevJob: [
          { place: 'Universidad de los Andes', title: 'Administradora de serviços' },
          { place: 'Promedon', title: 'Gestão de pessoas' },
        ],
      },
      maria_jose: {
        job: 'Chief Commercial Officer (CCO)',
        prevJob: [
          { place: 'PUC', title: 'Engenheira Comercial' },
          { place: 'BUK', title: 'Sales Lead' },
        ],
      },
      harold: {
        job: 'Desenvolvedor',
        prevJob: [
          { place: 'PUC', title: 'Engenheiro Industrial TI' },
          { place: 'MACH', title: 'Software Engineer' },
        ],
      },
      anibal: {
        job: 'Desenvolvedor',
        prevJob: [
          { place: 'PUC', title: 'Engenheiro Industrial Computação' },
          { place: 'Aurous', title: 'Software Engineer' },
        ],
      },
    },
    contact: {
      title: 'Quer saber mais?',
      subtitle: 'Deixe-nos seu e-mail e entraremos em contato com você o mais breve possível.',
      sendEmailButton: 'Enviar',
      details: {
        title: 'Contato',
        email: 'E-mail',
        phone: 'Telefone',
        address: 'Endereço',
        investors: 'Investidores',
      },
    },
    education: {
      title: 'Aprenda com Relif',
      tipsTitle: 'Nosso conselho',
      interactiveExamples: [
        {
          title: 'Como financio minha compra?',
          description: 'Isso é quanto você gastaria ao comprar uma geladeira com diferentes meios de financiamento',
          chartBars: [
            {
              name: 'Pagamento à vista',
              value: 500,
              label: 'US$500',
            },
            {
              name: 'Crédito ao consumidor (2 anos)',
              value: 821,
              label: 'US$821 (+64%)',
            },
            {
              name: 'Empréstimo informal (2 ano)',
              value: 2000,
              label: 'US$2.000 (+300%)',
            },
          ],
          tips: [
            'No ótimo, os créditos são para projetos (casa própria, estudos filhos, etc), e não para o dia a dia',
            'Mesmo que você chegue ao fim do mês, comece a trabalhar na poupança. (qualquer quantia serve)',
          ],
        },
        {
          title: 'Quanto tenho que pagar do cartão?',
          description:
            'Se o valor faturado do seu cartão é de US$100, isso é quanto você pagaria no total, dependendo do que você paga no final do mês',
          chartBars: [
            {
              name: 'Pagando tudo',
              value: 100,
              label: 'US$100',
            },
            {
              name: 'Pagando em 4 parcelas',
              value: 106,
              label: 'US$105 (+5%)',
            },
            {
              name: 'Pagando o mínimo (5%)',
              value: 175,
              label: 'US$175 (+75%)',
            },
          ],
          tips: [
            'Sempre que puder, pague o total do cartão',
            'Se você não puder pagar tudo, pague o máximo possível',
            'Evite pagar o mínimo',
          ],
        },
        {
          title: 'O que acontece se eu atrasar minhas parcelas?',
          description: 'Como o custo de uma parcela de US$200 aumenta à medida que atraso o pagamento',
          chartBars: [
            {
              name: 'Pagamento em dia',
              value: 200,
              label: 'US$200',
            },
            {
              name: '15 dias de atraso',
              value: 203.5,
              label: 'US$203,5 (+1,75%)',
            },
            {
              name: '1 mês de atraso',
              value: 207,
              label: 'US$225 (+12,5%)',
            },
            {
              name: '3 meses de atraso',
              value: 240,
              label: 'US$240 (+20%)',
            },
          ],
          tips: [
            'Geralmente é melhor renegociar um empréstimo do que viver com 1 ou 2 parcelas vencidas',
            'Tente evitar um atraso de mais de 3 meses, você pode acabar em um processo e pode perder seus ativos',
          ],
        },
      ],
    },
  },
};

export default portugueseTranslation;
