/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// @ts-ignore
import { Input } from '@dscla/ds-input/react';
import PrimaryBtn from 'components/UI/Buttons';
import useQuery from 'hooks/useQuery';
import Error from 'pages/Error/ErrorAlert';
import { format } from 'rut.js';
import { Origin } from 'services/types/origin';
import trpc from 'trpc';
import validateEmail from 'utils/validations/validateEmail';
import validatePhone from 'utils/validations/validatePhone';

import styles from '../UniqueDebtFlow.module.scss';
import COMPANIES from './Companies';
import ModalWithHeader from './ModalWithHeader';

interface Props {
  onSuccess: () => void;
  origin: Origin;
  rut: string;
  setRut: (value: string) => void;
  phone: string;
  setPhone: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  externalProviderId?: string;
  redirectUrl?: string;
}

const SetRutPhoneAndEmail: FC<Props> = ({
  onSuccess,
  origin,
  rut,
  setRut,
  phone,
  setPhone,
  email,
  setEmail,
  externalProviderId,
  redirectUrl,
}) => {
  const findOrCreateUser = trpc.uniqueFlowUsers.findOrCreateUser.useMutation();

  const { companyName } = useParams<{ companyName: string }>();
  const company = COMPANIES.find((x) => x.id === companyName);
  const query = useQuery();
  const referrer = Number(query.get('referrer')) || null;

  const validate = () => {
    return rut && validatePhone(phone) && validateEmail(email);
  };

  useEffect(() => {
    if (findOrCreateUser.isSuccess) {
      onSuccess();
    }
  }, [findOrCreateUser.isSuccess]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate() && !findOrCreateUser.isLoading) {
      findOrCreateUser.mutate({
        rut,
        phone,
        email,
        origin: origin || 'UniqueFlow',
        companyName,
        referrer,
        externalProviderId,
      });
    }
  };

  const getText = () => {
    if (company?.id === 'caja-los-andes' || origin === 'Caja Los Andes') {
      return 'Mejora tus deudas y asesórate gratis. Aprovecha este beneficio Caja los Andes junto a Relif.';
    }
    if (company?.id === 'rrss') {
      return 'Relif te ofrece este beneficio para mejorar tus finanzas y tu educación financiera.';
    }
    const finalText = 'mejorar tus finanzas y tu educación financiera.';
    let returnText = '';
    if (origin === 'Banco de Chile') {
      returnText = `Banco de Chile y Relif han hecho una alianza para ayudarte a mejorar tus deudas. `;
    }
    if ((origin === null || origin === 'UniqueFlow') && company && company.id !== 'caja-los-andes') {
      return `${returnText}${company.name} y Relif te ofrecen este nuevo beneficio para ayudarte a ${finalText}`;
    }
    return `${returnText}Relif te ayudará a ${finalText}`;
  };

  return (
    <ModalWithHeader origin={origin} title="¡Te damos la bienvenida!">
      <Error
        message="Hubo un error, si el problema persiste contáctanos a través del chat"
        isOpen={findOrCreateUser.isError}
      />
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e)}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        mt={2}
      >
        <Box className={styles.ContentBox}>
          <Typography variant="body1" width="100%" textAlign="left" pt={1} pb={2} fontSize="20px">
            {getText()}
          </Typography>
          <Stack spacing={1}>
            <Input
              type="rut"
              label="Rut"
              value={rut}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setRut(format(e.target.value))}
            />
            <Input
              type="email"
              label="Email"
              value={email}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim())}
              placeholder="juan@email.cl"
            >
              <div slot="icon">
                <Email />
              </div>
            </Input>
            <Input
              type="tel"
              label="Celular"
              value={phone}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
            >
              <div slot="icon">
                <Phone />
              </div>
            </Input>
          </Stack>
        </Box>
        <Box display="grid" justifyContent="center" alignItems="center" py={5}>
          <PrimaryBtn type="submit" disabled={!validate()}>
            Comenzar
          </PrimaryBtn>
          {!redirectUrl && (
            <Typography py={2} textAlign="center">
              ¿Ya tienes cuenta?{' '}
              <Link to="/sign-in" style={{ textDecoration: 'underline' }}>
                Inicia Sesión
              </Link>
            </Typography>
          )}
          {redirectUrl && (
            <Typography py={2} textAlign="center">
              ¿Quiéres hacerlo más tarde?{' '}
              <Link to={redirectUrl} style={{ textDecoration: 'underline' }}>
                Seguir al chat
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
    </ModalWithHeader>
  );
};

SetRutPhoneAndEmail.defaultProps = {
  externalProviderId: undefined,
  redirectUrl: undefined,
};

export default SetRutPhoneAndEmail;
