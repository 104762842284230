import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MonitorHeart } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

import { WhatsAppIcon, BudgetsIcon, MoneyIcon, ProfileIcon } from 'assets/icons';
import useCurrentRoute from 'hooks/useCurrentRoute';
import useDebtFlow from 'hooks/useDebtFlow';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

export const BottomNavBar = () => {
  const navigate = useNavigate();
  const { path } = useCurrentRoute();
  const debtFlow = useDebtFlow();
  const [getUser, user] = useLazyGetUserInformationQuery();
  const companyName = user?.data?.company?.name;

  useEffect(() => {
    getUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
  }, []);

  const financialAdvisorFirstSection = [
    {
      text: 'Chat',
      path: '/chat',
      Icon: <WhatsAppIcon />,
      isSelected: path.includes('/chat'),
    },
    {
      text: 'Presupuesto',
      path: '/budget',
      Icon: <BudgetsIcon />,
      isSelected: path.includes('/budget'),
    },
  ];

  if (!debtFlow.hasCMFResponseInLast30Days) {
    financialAdvisorFirstSection.push({
      text: 'Escáner financiero',
      path: `/onboarding/${companyName?.toLowerCase() || 'chat'}?redirectUrl=/chat?refetchFlag=1`,
      Icon: <MonitorHeart />,
      isSelected: path.includes('/onboarding'),
    });
  } else {
    financialAdvisorFirstSection.push({
      text: 'Perfil financiero',
      path: '/debt-profile',
      Icon: <MoneyIcon />,
      isSelected: path.includes('/debt-profile'),
    });
  }

  financialAdvisorFirstSection.push({
    text: 'Mi perfil',
    path: '/profile',
    Icon: <ProfileIcon />,
    isSelected: path.includes('/profile'),
  });

  return (
    <BottomNavigation
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      value={financialAdvisorFirstSection.findIndex((section) => section.isSelected)}
      onChange={(event, newValue: string) => {
        navigate(newValue);
      }}
    >
      {financialAdvisorFirstSection.map((section) => (
        <BottomNavigationAction
          key={section.text}
          label=""
          value={section.path}
          icon={section.Icon}
          sx={{
            color: section.isSelected ? 'info.main' : 'text.primary',
          }}
        />
      ))}
    </BottomNavigation>
  );
};

export default BottomNavBar;
