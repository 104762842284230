import { FunctionComponent, lazy } from 'react';

import Admin from 'pages/Admin';
import ActiveUsers from 'pages/Admin/components/ActiveUsers';
import AllowedUsers from 'pages/Admin/components/AllowedUsers';
import BankOfferRequests from 'pages/Admin/components/BankOfferRequests';
import BankOfferRequest from 'pages/Admin/components/BankOfferRequests/BankOfferRequest';
import AdminBankOffers from 'pages/Admin/components/BankOffers';
import Companies from 'pages/Admin/components/Companies';
import Constants from 'pages/Admin/components/Constants';
import DbReader from 'pages/Admin/components/DbReader';
import Debts from 'pages/Admin/components/Debts';
import MetricsDashboard from 'pages/Admin/components/MetricsDashboard';
import OpenAIAdministrator from 'pages/Admin/components/OpenAIAdministrator';
import RoiCalculatorAdmin from 'pages/Admin/components/RoiCalculator';
import SurveyResults from 'pages/Admin/components/SurveyResults';
import Users from 'pages/Admin/components/Users';
import WhatsAppChat from 'pages/Admin/components/WhatsAppChat';
import AraucanaProspects from 'pages/AraucanaProspects';
import BancameSignDocument from 'pages/BancameSignMandate';
import BankOffers from 'pages/BankOffers';
import Blog from 'pages/Blog';
import Article from 'pages/Blog/Article';
import LandingPageArticle from 'pages/Blog/LandingPageArticle';
import LandingPageBlog from 'pages/Blog/LandingPageBlog';
import BookMeeting from 'pages/BookMeeting';
import Budget from 'pages/Budget';
import BukDashboard from 'pages/BukDashboard';
import CLAVirtualAdvisorDashboard from 'pages/CLAVirtualAdvisorDashboard';
import Chat from 'pages/Chat';
import ClaMetrics from 'pages/ClaMetrics';
import ClaveUnicaWhatsApp from 'pages/ClaveUnicaWhatsApp';
import ComputerUnlocked from 'pages/ComputerUnlocked';
import DebtProfile from 'pages/DebtProfile';
import DebtCreation from 'pages/Debts';
import DebtsReport from 'pages/Debts/components/DebtsReport';
import EditOrCreateBudget from 'pages/EditOrCreateBudget';
import ErrorPage from 'pages/Error/ErrorPage';
import FAQ from 'pages/FAQ';
import HumanResourcesAdmin from 'pages/HumanResourcesAdmin';
import HumanResourcesAllowedUsers from 'pages/HumanResourcesAdmin/components/allowedUsers';
import Metrics from 'pages/HumanResourcesAdmin/components/metrics';
// import FinancialHealth from 'pages/FinancialHealth';
import Icons from 'pages/Icons';
import LandingPageNew from 'pages/LandingPageNew';
import LankDashboard from 'pages/LankDashboard';
import LunchTool from 'pages/LunchTool';
import MyPlan from 'pages/MyPlan';
import NewHome from 'pages/NewHome';
import Mortgages from 'pages/NewHome/Mortgages';
import NoSubsidy from 'pages/NewHome/NoSubsidy';
import SavingAccounts from 'pages/NewHome/SavingAccounts';
import WithSubsidy from 'pages/NewHome/WithSubsidy';
import PasswordReset from 'pages/PasswordReset';
import Playground from 'pages/Playground';
import Profile from 'pages/Profile';
import RequestedBankOffer from 'pages/RequestedBankOffer';
import RoiCalculator from 'pages/RoiCalculator';
import SignIn from 'pages/SignIn';
import SignDocumentAnyUser from 'pages/SignMandateAnyUser';
import SignUp from 'pages/SignUp';
import SubscribedToWebinar from 'pages/SubscribedToWebinar';
import TappDashboard from 'pages/TappDashboard';
import ThemeDemo from 'pages/ThemeDemo';
import TyC from 'pages/TyC';
import Unauthorized from 'pages/Unauthorized';
import UniqueDebtFlow from 'pages/UniqueDebtFlow';
import WelcomePanel from 'pages/WelcomePanel';

// const LandingPage = React.lazy(() => import('pages/LandingPage/App'));
// const DebtCreation = React.lazy(() => import('pages/Debts'));
// const MyPlan = React.lazy(() => import('pages/MyPlan'));
// const Admin = lazy(() => import('pages/Admin'));
const FinancialHealth = lazy(() => import('pages/FinancialHealth'));
const DebtLab = lazy(() => import('pages/Admin/components/DebtLab'));
// const EditOrCreateBudget = React.lazy(() => import('pages/EditOrCreateBudget'));

export type Route = {
  title: string;
  path: string;
  component: FunctionComponent;
  private: boolean;
  index: boolean;
  noLayout?: boolean;
  disableChatButton?: boolean;
  isFinancialAdvisorExclusive?: boolean;
  isNonFinancialAdvisorExclusive?: boolean;
};

const routes: { [key: string]: Route } = {
  '/sign-up': {
    title: 'Crear Usuario',
    path: '/sign-up',
    component: SignUp,
    private: false,
    index: false,
  },
  '/sign-in': {
    title: 'Iniciar Sesión',
    path: '/sign-in',
    component: SignIn,
    private: false,
    index: false,
  },
  '/debt': {
    title: 'Deudas',
    path: '/debt',
    component: DebtCreation,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/debt/debts-report': {
    title: 'Deudas',
    path: '/debt/debts-report',
    component: DebtsReport,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/profile': {
    title: 'Perfil',
    path: '/profile',
    component: Profile,
    private: true,
    index: false,
  },
  '/budget': {
    title: 'Presupuesto',
    path: '/budget',
    component: Budget,
    private: true,
    index: false,
  },
  '/welcome': {
    title: 'Inicio',
    path: '/welcome',
    component: LandingPageNew,
    private: false,
    index: false,
  },
  '/welcome/es': {
    title: 'Inicio',
    path: '/welcome/es',
    component: LandingPageNew,
    private: false,
    index: false,
  },
  '/welcome/cl': {
    title: 'Inicio',
    path: '/welcome/cl',
    component: LandingPageNew,
    private: false,
    index: false,
  },
  '/budget/new': {
    title: 'Crear Presupuesto',
    path: '/budget/new',
    component: EditOrCreateBudget,
    private: true,
    index: false,
  },
  '/': {
    title: 'Bienvenido',
    path: '/',
    component: WelcomePanel,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/index': {
    title: 'Bienvenido',
    path: '/index',
    component: WelcomePanel,
    private: true,
    index: false,
  },
  '/my-plan': {
    title: 'Mi plan',
    path: '/my-plan',
    component: MyPlan,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/admin': {
    title: 'Administrador',
    path: '/admin',
    component: Admin,
    private: true,
    index: false,
  },
  '/admin/allowed-users': {
    title: 'Usuarios permitidos',
    path: '/admin/allowed-users',
    component: AllowedUsers,
    private: true,
    index: false,
  },
  '/admin/bank-offers': {
    title: 'Ofertas Bancos',
    path: '/admin/bank-offers',
    component: AdminBankOffers,
    private: true,
    index: false,
  },
  '/admin/chat': {
    title: 'Chat WhatsApp',
    path: '/admin/chat',
    component: WhatsAppChat,
    private: true,
    index: false,
  },
  '/admin/companies': {
    title: 'Empresas',
    path: '/admin/companies',
    component: Companies,
    private: true,
    index: false,
  },
  '/admin/constants': {
    title: 'Constantes',
    path: '/admin/constants',
    component: Constants,
    private: true,
    index: false,
  },
  '/admin/debts': {
    title: 'Deudas',
    path: '/admin/debts',
    component: Debts,
    private: true,
    index: false,
  },
  '/admin/metrics': {
    title: 'Métricas',
    path: '/admin/metrics',
    component: MetricsDashboard,
    private: true,
    index: false,
  },
  '/admin/openAI': {
    title: 'Administrador OpenAI',
    path: '/admin/openAI',
    component: OpenAIAdministrator,
    private: true,
    index: false,
  },
  '/admin/users': {
    title: 'Usuarios',
    path: '/admin/users',
    component: Users,
    private: true,
    index: false,
  },
  '/admin/users/debts-report': {
    title: 'Boletín comercial',
    path: '/admin/users/debts-report',
    component: DebtsReport,
    private: true,
    index: false,
  },
  '/admin/roi-calculators': {
    title: 'Calculadoras de ROI',
    path: '/admin/roi-calculators',
    component: RoiCalculatorAdmin,
    private: true,
    index: false,
  },
  '/admin/survey-results': {
    title: 'Resultados Encuestas',
    path: '/admin/survey-results',
    component: SurveyResults,
    private: true,
    index: false,
  },
  '/admin/debt-lab': {
    title: 'Admin - Deudas - Lab',
    path: '/admin/debt-lab',
    component: DebtLab,
    private: true,
    index: false,
  },
  '/admin/active-users': {
    title: 'Admin - Usuarios Activos',
    path: '/admin/active-users',
    component: ActiveUsers,
    private: true,
    index: false,
  },
  '/budget/edit': {
    title: 'Editar presupuesto',
    path: '/budget/edit',
    component: EditOrCreateBudget,
    private: true,
    index: false,
  },
  '/education': {
    title: 'Educación Financiera',
    path: '/education',
    component: Blog,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/education/:id': {
    title: 'Educación Financiera',
    path: '/education/:id',
    component: Article,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/blog': {
    title: 'Educación Financiera',
    path: '/blog',
    component: LandingPageBlog,
    private: false,
    index: false,
  },
  '/blog/:id': {
    title: 'Educación Financiera',
    path: '/blog/:id',
    component: LandingPageArticle,
    private: false,
    index: false,
  },
  '/newTheme': {
    title: 'New Theme',
    path: '/newTheme',
    component: ThemeDemo,
    private: true,
    index: false,
  },
  '/faq': {
    title: 'Preguntas frecuentes',
    path: '/faq',
    component: FAQ,
    private: true,
    index: false,
  },
  '/password-reset': {
    title: 'Resetear Contraseña',
    path: '/password-reset',
    component: PasswordReset,
    private: false,
    index: false,
  },
  '/lockpc': {
    title: 'Bloquea el computador',
    path: '/lockpc',
    component: ComputerUnlocked,
    private: false,
    index: false,
  },
  '/lunch-tool': {
    title: 'Lunch Tool',
    path: '/lunch-tool',
    component: LunchTool,
    private: false,
    index: false,
  },
  '/alianza-araucana': {
    title: 'Alianza La Araucana',
    path: '/alianza-araucana',
    component: AraucanaProspects,
    private: false,
    index: false,
  },
  '/sign-mandate': {
    title: 'Firmar Mandato',
    path: '/sign-mandate',
    component: SignDocumentAnyUser,
    private: false,
    index: false,
  },
  '/bancame-sign-mandate': {
    title: 'Firmar Mandato',
    path: '/bancame-sign-mandate',
    component: BancameSignDocument,
    private: false,
    index: false,
  },
  '/requested-bank-offer': {
    title: 'Solicitud de Oferta Bancaria',
    path: '/requested-bank-offer',
    component: RequestedBankOffer,
    private: false,
    index: false,
  },
  '/icons': {
    title: 'Icons',
    path: '/icons',
    component: Icons,
    private: true,
    index: false,
  },
  '/unauthorized': {
    title: 'Unauthorized',
    path: '/unauthorized',
    component: Unauthorized,
    private: true,
    index: false,
  },
  '/health': {
    title: 'Salud Financiera',
    path: '/health',
    component: FinancialHealth,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/roi-calculator': {
    title: 'Calculadora ROI',
    path: '/roi-calculator',
    component: RoiCalculator,
    private: false,
    index: false,
  },
  '/bank-offers': {
    title: 'Ofertas Bancos',
    path: '/bank-offers',
    component: BankOffers,
    private: false,
    index: false,
  },
  '/tyc': {
    title: 'Términos y Condiciones',
    path: '/tyc',
    component: TyC,
    private: false,
    index: false,
  },
  '/claveUnicaWhatsApp': {
    title: 'Clave Única WhatsApp',
    path: '/claveUnicaWhatsApp',
    component: ClaveUnicaWhatsApp,
    private: false,
    index: false,
  },
  '/book-meeting': {
    title: 'Reuniones',
    path: '/book-meeting',
    component: BookMeeting,
    private: false,
    index: false,
  },
  '/new-home': {
    title: 'Casa Propia',
    path: '/new-home',
    component: NewHome,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/new-home/withSubsidy': {
    title: 'Casa Propia',
    path: '/new-home/withSubsidy',
    component: WithSubsidy,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/new-home/noSubsidy': {
    title: 'Casa Propia',
    path: '/new-home/noSubsidy',
    component: NoSubsidy,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/new-home/saving-accounts': {
    title: 'Casa Propia',
    path: '/new-home/saving-accounts',
    component: SavingAccounts,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/new-home/mortgages': {
    title: 'Casa Propia',
    path: '/new-home/mortgages',
    component: Mortgages,
    private: true,
    index: false,
    isNonFinancialAdvisorExclusive: true,
  },
  '/hr': {
    title: 'Administrador RR.HH.',
    path: '/hr',
    component: HumanResourcesAdmin,
    private: true,
    index: false,
  },
  '/hr/metrics': {
    title: 'Administrador RR.HH.',
    path: '/hr/metrics',
    component: Metrics,
    private: true,
    index: false,
  },
  '/hr/allowed-users': {
    title: 'Administrador RR.HH.',
    path: '/hr/allowed-users',
    component: HumanResourcesAllowedUsers,
    private: true,
    index: false,
  },
  '/caja-los-andes-debt-flow': {
    title: 'Caja Los Andes',
    path: '/caja-los-andes-debt-flow',
    component: UniqueDebtFlow,
    private: false,
    index: false,
  },
  '/banco-chile-debt-flow': {
    title: 'Banco de Chile',
    path: '/banco-chile-debt-flow',
    component: UniqueDebtFlow,
    private: false,
    index: false,
  },
  '/banco-chile-debt-flow-iansa': {
    title: 'Banco de Chile',
    path: '/banco-chile-debt-flow-iansa',
    component: UniqueDebtFlow,
    private: false,
    index: false,
  },
  '/admin/bank-offer-requests': {
    title: 'Solicitudes de refinanciamiento',
    path: '/admin/bank-offer-requests',
    component: BankOfferRequests,
    private: true,
    index: false,
  },
  '/admin/bank-offer-requests/:id': {
    title: 'Solicitud de refinanciamiento',
    path: '/admin/bank-offer-requests/:id',
    component: BankOfferRequest,
    private: true,
    index: false,
  },
  '/admin/db-reader': {
    title: 'Analizador de Base de Datos',
    path: '/admin/db-reader',
    component: DbReader,
    private: true,
    index: false,
  },
  '/playground': {
    title: 'Playground',
    path: '/playground/:messageId',
    component: Playground,
    private: true,
    index: false,
    noLayout: true,
  },
  '/onboarding': {
    title: 'Onboarding',
    path: '/onboarding/:companyName',
    component: UniqueDebtFlow,
    private: false,
    index: false,
  },
  '/subscribed-to-webinar': {
    title: 'Webinar',
    path: '/subscribed-to-webinar/:userId',
    component: SubscribedToWebinar,
    private: false,
    index: false,
  },
  '/cla-metrics': {
    title: 'Métricas CLA',
    path: '/cla-metrics',
    component: ClaMetrics,
    private: true,
    index: false,
  },
  '/chat': {
    title: 'chat',
    path: '/chat',
    component: Chat,
    private: true,
    index: false,
    disableChatButton: true,
    isFinancialAdvisorExclusive: true,
  },
  '/debt-profile': {
    title: 'Perfil financiero',
    path: '/debt-profile',
    component: DebtProfile,
    private: true,
    index: false,
    disableChatButton: true,
    isFinancialAdvisorExclusive: true,
  },
  '/buk/dashboard': {
    title: 'Buk Dashboard',
    path: '/buk/dashboard',
    component: BukDashboard,
    private: false,
    index: false,
    noLayout: true,
    disableChatButton: true,
  },
  '/cla/dashboard': {
    title: 'CLA Dashboard',
    path: '/cla/dashboard',
    component: TappDashboard,
    private: false,
    index: false,
    noLayout: true,
    disableChatButton: true,
  },
  '/cla-virtual-advisor/dashboard': {
    title: 'Asesor Virtual CLA',
    path: '/cla-virtual-advisor/dashboard',
    component: CLAVirtualAdvisorDashboard,
    private: false,
    index: false,
    noLayout: true,
    disableChatButton: true,
  },
  '/lank/dashboard': {
    title: 'Lank Dashboard',
    path: '/lank/dashboard',
    component: LankDashboard,
    private: false,
    index: false,
    noLayout: true,
    disableChatButton: true,
  },
  // this path must be the last one
  error: {
    title: 'Error',
    path: '*',
    component: ErrorPage,
    private: true,
    index: false,
  },
};

export default routes;
