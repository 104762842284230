import React from 'react';

import { Box, Grid, Avatar, Typography } from '@mui/material';

import priceFormat from 'services/format/priceFormat';
import { Debt } from 'services/types/debt';
import showDebtIcon from 'utils/showDebtIcon';

const DebtCard = ({ debt, isLastRow }: { debt: Debt; isLastRow: boolean }) => {
  return (
    <Box sx={{ py: 1, borderBottom: isLastRow ? 'none' : '1px solid #e0e0e0' }}>
      <Grid container>
        <Grid item xs={2}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}
          >
            <Avatar
              sx={{
                bgcolor: '#EDF1FA',
                color: 'primary.main',
                width: '40px',
                height: '40px',
              }}
            >
              {showDebtIcon(debt, 40)}
            </Avatar>
          </Box>
        </Grid>
        <Grid item xs={6} px={1}>
          <Typography variant="h4" fontWeight={400} fontSize="16px">
            {debt.institutionName}
          </Typography>
          <Typography variant="h5" fontSize="12px">
            {`${debt.debtType?.charAt(0).toUpperCase() || ''}${debt.debtType?.slice(1) || ''}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" fontWeight={400} fontSize="16px" textAlign="right">
            {priceFormat.format(debt.amount || 0)}
          </Typography>
          <Typography variant="h5" fontSize="12px" textAlign="right">
            Cuota: {priceFormat.format(debt.monthlyPayment || 0)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DebtCard;
