import React from 'react';

import { Box, Grid, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import useIsMobile from 'hooks/useIsMobile';

import styles from '../LankDashboard.module.scss';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  text: string;
  handleClick: () => Promise<void>;
}

const ChatButton = ({ Icon, text, handleClick }: Props) => {
  const { isMobile } = useIsMobile();

  return (
    <Grid item xs={isMobile ? 12 : 4}>
      <Box
        className={styles.ButtonContainer}
        onClick={() => {
          handleClick().catch(() => {
            console.error('Error signing up'); // eslint-disable-line no-console
          });
        }}
      >
        <Box className={styles.Button}>
          <Box className={styles.IconContainer}>
            <Icon sx={{ fontSize: 40 }} />
          </Box>
          <Typography variant="body1" textAlign="center" width="100%" pr={4} fontWeight="bold">
            {text}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default ChatButton;
