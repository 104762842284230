import type { FloidCMFResponse } from '@relif/floid-helpers';

import baseApi from './baseApi';

const floidAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCMFRequestLast30Days: build.query({
      query: () => ({
        url: '/floidRequests/cmf-last-30-days',
        method: 'GET',
      }),
      transformResponse: (response: boolean) => response,
    }),
    getLastCMFRequest: build.query({
      query: () => ({
        url: '/floidRequests/last-cmf-request',
        method: 'GET',
      }),
      transformResponse: (response: FloidCMFResponse | null) => response,
    }),
    updateCamaraComercio: build.mutation({
      query: () => ({
        url: '/floidRequests/update-camara-comercio',
        method: 'POST',
      }),
      transformResponse: (response) => response,
    }),
    getLast30DaysCMFRequest: build.query({
      query: () => ({
        url: '/floidRequests/cmf-last-30-days-request',
        method: 'GET',
      }),
      transformResponse: (response: FloidCMFResponse | null) => response,
    }),
    getCamaraComercioLast30Days: build.query({
      query: () => ({
        url: '/floidRequests/camara-comercio-last-30-days',
        method: 'GET',
      }),
      transformResponse: (response: boolean) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCMFRequestLast30DaysQuery,
  useLazyGetCMFRequestLast30DaysQuery,
  useGetLastCMFRequestQuery,
  useUpdateCamaraComercioMutation,
  useGetLast30DaysCMFRequestQuery,
  useLazyGetLast30DaysCMFRequestQuery,
  useGetCamaraComercioLast30DaysQuery,
} = floidAPI;

export default floidAPI;
