import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Grid, Typography } from '@mui/material';

import RelifLogo from 'assets/images/logo.webp';
import LankLogo from 'assets/images/logos/companies/lank.svg';
import { checkIfTokenIsValid } from 'authStorage';
import FinancialAdvisorAuthModal from 'components/common/FinancialAdvisorAuthModal';
import useAccessToken from 'hooks/useAccessToken';
import useDebtFlow from 'hooks/useDebtFlow';
import useIsMobile from 'hooks/useIsMobile';
import { useLazyGetCMFRequestLast30DaysQuery } from 'services/api/floid.api';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

import styles from './LankDashboard.module.scss';
import ChatButton from './components/ChatButton';

const LANK_COMPANY_ID = 57;

interface TitleProps {
  isMobile: boolean;
}
const Title: React.FC<TitleProps> = ({ isMobile }) => (
  <Typography
    variant="h1"
    style={{ fontSize: isMobile ? '40px' : '50px', color: 'black', fontWeight: 400, margin: 0, lineHeight: '1.2' }}
  >
    ¡Hola!
  </Typography>
);

const DescriptionInfoText = () => (
  <>
    <Typography variant="h2" mb={2}>
      Bienvenido a la plataforma de salud financiera de Relif en alianza con Lank.
    </Typography>
    <Typography variant="body1">
      Acá podrás encontrar asesoría personalizada y soluciones para mejorar tu endeudamiento, ahorro, orden financiero y
      mucho más.
    </Typography>
  </>
);

const Footer = ({ isMobile }: { isMobile: boolean }) => (
  <Box className={styles.Footer}>
    <Box sx={{ backgroundColor: 'rgba(245, 102, 63, 1)', borderRadius: '10px' }}>
      <img src={LankLogo} alt="Lank Logo" width={isMobile ? '80px' : '100px'} />
    </Box>
    <Box>
      <img src={RelifLogo} alt="Relif Logo" width={isMobile ? '100px' : '140px'} />
    </Box>
  </Box>
);

const LankDashboard = () => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const token = useAccessToken();
  const tokenIsValid = checkIfTokenIsValid(token);
  const debtFlow = useDebtFlow();
  const [getUserInformation, { data: userInformation }] = useLazyGetUserInformationQuery();
  const [getCMFRequestLast30Days, { isSuccess: isCMFRequestLast30DaysSuccess }] = useLazyGetCMFRequestLast30DaysQuery();

  const handleClick = async () => {
    setIsSignUpModalOpen(true);
  };

  // Fetch user information once the user is logged in
  useEffect(() => {
    if (tokenIsValid) {
      getUserInformation(null).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching user information');
      });
    }
  }, [tokenIsValid]);

  // Fetch CMF request last 30 days once the user information is fetched
  useEffect(() => {
    // CMF information can only be fetched if the user has a rut
    if (userInformation && userInformation.rut) {
      getCMFRequestLast30Days(null).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching CMF request last 30 days');
      });
    }
    // If the user does not have a rut, redirect to the onboarding page
    if (userInformation && !userInformation.rut) {
      navigate('/onboarding/lank?redirectUrl=/chat?refetchFlag=1');
    }
  }, [userInformation]);

  // Redirect to the chat page if the user has a CMF response in the last 30 days
  useEffect(() => {
    if (!isCMFRequestLast30DaysSuccess) return;
    // If the user has a CMF response in the last 30 days, redirect to the chat page
    if (debtFlow.hasCMFResponseInLast30Days) {
      navigate('/chat');
    } else {
      // If the user does not have a CMF response in the last 30 days, redirect to the onboarding page
      navigate('/onboarding/lank?redirectUrl=/chat?refetchFlag=1');
    }
  }, [debtFlow.hasCMFResponseInLast30Days, isCMFRequestLast30DaysSuccess]);

  if (isMobile) {
    return (
      <Box className={styles.DashboardContainer}>
        <FinancialAdvisorAuthModal
          open={isSignUpModalOpen}
          onClose={() => setIsSignUpModalOpen(false)}
          companyId={LANK_COMPANY_ID}
        />
        <Box className={styles.Card} display="flex" flexDirection="column">
          <Box className={styles.DescriptionContainer}>
            <Box className={styles.Title}>
              <Title isMobile={isMobile} />
            </Box>
            <Box className={styles.DescriptionInfo}>
              <DescriptionInfoText />
              <ChatButton Icon={ArrowRightIcon} text="Comenzar" handleClick={handleClick} />
              <Footer isMobile={isMobile} />
            </Box>
          </Box>
          <Box className={styles.Illustration} />
        </Box>
      </Box>
    );
  }
  return (
    <Box className={styles.DashboardContainer}>
      <FinancialAdvisorAuthModal
        open={isSignUpModalOpen}
        onClose={() => setIsSignUpModalOpen(false)}
        companyId={LANK_COMPANY_ID}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box className={`${styles.Card} ${styles.MainCard}`}>
            <Grid container>
              <Grid item xs={4}>
                <Box className={styles.Illustration} />
              </Grid>
              <Grid item xs={8}>
                <Box className={styles.DescriptionContainer}>
                  <Box className={styles.Title}>
                    <Title isMobile={isMobile} />
                  </Box>
                  <Box className={styles.DescriptionInfo}>
                    <DescriptionInfoText />
                    <Box mt={2}>
                      <ChatButton Icon={ArrowRightIcon} text="Comenzar" handleClick={handleClick} />
                    </Box>
                    <Footer isMobile={isMobile} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LankDashboard;
