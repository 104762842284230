import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { createClient } from '@supabase/supabase-js';
import useIsMobile from 'hooks/useIsMobile';

interface Person {
  username: string;
  company: string;
  lunchPreferences: string;
  bank_account: string;
}

interface LunchRow {
  username: string;
  needsLunch: boolean;
  preferences: string;
  date: string;
}
const useSupabase = () => {
  const [supabase] = useState(() =>
    createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_ANON_PUBLIC_KEY)
  );

  return supabase;
};

function shuffleArrayForCurrentDate<T>(array: T[]): T[] {
  const date = new Date();
  const seededRandom = () => {
    // Using Fisher-Yates shuffle algorithm with a seeded random number generator
    let seed = date.getFullYear() + date.getMonth() + date.getDate();
    return () => {
      seed = (seed * 16807) % 73;
      return (seed - 1) / 73;
    };
  };
  const random = seededRandom();
  return array
    .map((element) => ({ element, randomNumber: random() }))
    .sort((a, b) => a.randomNumber - b.randomNumber)
    .map(({ element }) => element);
}

// Add this new component above the main LunchTool component
const SelectUser = ({
  currentUser,
  people,
  onChange,
  onEditClick,
}: {
  currentUser: string;
  people: Person[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEditClick: () => void;
}) => (
  <Box>
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h6" color="primary" fontWeight="500" mb={2}>
        Seleccionar Usuario
      </Typography>
      {currentUser && (
        <IconButton onClick={onEditClick} color="primary" sx={{ mb: 2 }}>
          <EditIcon />
        </IconButton>
      )}
    </Box>
    <TextField
      select
      fullWidth
      value={currentUser}
      onChange={onChange}
      size="small"
      SelectProps={{
        native: true,
      }}
    >
      <option value="" disabled>
        Selecciona un usuario
      </option>
      {people
        .sort((a, b) => a.username.localeCompare(b.username))
        .map((person) => (
          <option key={person.username} value={person.username}>
            {person.username} ({person.company})
          </option>
        ))}
    </TextField>
  </Box>
);

// Add new component for the preferences modal
const PreferencesModal = ({
  open,
  onClose,
  person,
  onSave,
}: {
  open: boolean;
  onClose: () => void;
  person: Person | null;
  onSave: (lunchPreferences: string, bankAccount: string) => Promise<void>;
}) => {
  const [lunchPreferences, setLunchPreferences] = useState(person?.lunchPreferences || '');
  const [bankAccount, setBankAccount] = useState(person?.bank_account || '');

  useEffect(() => {
    setLunchPreferences(person?.lunchPreferences || '');
    setBankAccount(person?.bank_account || '');
  }, [person]);

  const handleSave = async () => {
    await onSave(lunchPreferences, bankAccount);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ color: 'black' }}>Editar detalles de {person?.username}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          <TextField
            fullWidth
            label="Preferencias de almuerzo por defecto"
            value={lunchPreferences}
            onChange={(e) => setLunchPreferences(e.target.value)}
            placeholder="Ej: Vegetariano, Sin gluten, etc."
          />
          <TextField
            fullWidth
            label="Cuenta bancaria"
            value={bankAccount}
            onChange={(e) => setBankAccount(e.target.value)}
            placeholder="Número de cuenta"
            multiline
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={() => {
            handleSave().catch(console.error); // eslint-disable-line no-console
          }}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const LunchTool = () => {
  const [people, setPeople] = useState<Person[]>([]);
  const [rows, setRows] = useState<LunchRow[]>([]);
  const { isMobile } = useIsMobile();
  const [needsLunch, setNeedsLunch] = useState(false);
  const [preferences, setPreferences] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [currentUser, setCurrentUser] = useState(() => localStorage.getItem('lunchToolUser') || '');
  const supabase = useSupabase();
  const [preferencesModalOpen, setPreferencesModalOpen] = useState(false);
  const [showBankDetails, setShowBankDetails] = useState(false);
  // Add handler for user selection
  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedUser = event.target.value;
    setCurrentUser(selectedUser);
    localStorage.setItem('lunchToolUser', selectedUser);

    // Set default preferences from the selected user
    const selectedPerson = people.find((p) => p.username === selectedUser);
    if (selectedPerson) {
      setPreferences(selectedPerson.lunchPreferences || '');
    }
  };

  const fetchUsers = async () => {
    const { data } = await supabase.from('Users').select('*');
    setPeople((data as Person[]).sort((a, b) => a.username.localeCompare(b.username)));
    setPreferences((data as Person[]).find((p) => p.username === currentUser)?.lunchPreferences || '');
  };

  const fetchLunchToolRows = async () => {
    const { data } = await supabase.from('Lunch').select('*').eq('date', new Date().toISOString().split('T')[0]);
    setRows(
      (data as LunchRow[])
        .sort((a, b) => (a.needsLunch ? -1 : 1) - (b.needsLunch ? -1 : 1))
        .sort((a, b) => a.username.localeCompare(b.username))
    );
    const currentUserRow = (data as LunchRow[]).find((row) => row.username === currentUser);
    if (currentUserRow) {
      setNeedsLunch(currentUserRow.needsLunch);
      setPreferences(currentUserRow.preferences);
    } else {
      setHasUnsavedChanges(true);
    }
  };

  const saveLunchUpdate = async () => {
    const today = new Date().toISOString().split('T')[0];
    if (rows.some((row) => row.username === currentUser)) {
      await supabase
        .from('Lunch')
        .update({
          needsLunch,
          preferences,
        })
        .eq('username', currentUser)
        .eq('date', today);
    } else {
      await supabase.from('Lunch').insert({ username: currentUser, date: today, needsLunch, preferences });
    }
    setHasUnsavedChanges(false);
  };

  // Add new function to save user preferences
  const saveUserDetails = async (lunchPreferences: string, bankAccount: string) => {
    await supabase
      .from('Users')
      .update({
        lunchPreferences,
        bank_account: bankAccount,
      })
      .eq('username', currentUser);
    await fetchUsers();
  };

  useEffect(() => {
    const fetchUsersPromise = fetchUsers();
    const fetchLunchToolRowsPromise = fetchLunchToolRows();

    // subscribe to new rows or updates
    supabase
      .channel('lunch-tool')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'Lunch' }, (payload) => {
        // eslint-disable-next-line no-console
        console.log(payload);
        // eslint-disable-next-line no-console
        fetchLunchToolRows().catch(console.error);
      })
      .subscribe();
    return () => {
      // eslint-disable-next-line no-console
      fetchUsersPromise.catch(console.error);
      // eslint-disable-next-line no-console
      fetchLunchToolRowsPromise.catch(console.error);

      // eslint-disable-next-line no-console
      supabase.removeAllChannels().catch(console.error);
    };
  }, []);

  const randomPeople = shuffleArrayForCurrentDate(rows.filter((person) => person.needsLunch)).slice(0, 2);

  // If no user is selected, show only the user selector
  if (!currentUser) {
    return (
      <Box
        minHeight="100vh"
        width="100vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          backgroundColor: '#f5f5f5',
          py: 4,
          minHeight: '100vh',
          height: '100%',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: isMobile ? '95%' : '60%',
            maxWidth: '800px',
            p: 4,
          }}
        >
          <Typography variant="h2" color="primary" textAlign="center" fontWeight="bold">
            Herramienta de almuerzo
          </Typography>
          <SelectUser
            currentUser={currentUser}
            people={people}
            onChange={handleUserChange}
            onEditClick={() => setPreferencesModalOpen(true)}
          />
        </Paper>
      </Box>
    );
  }

  return (
    <Box
      minHeight="100vh"
      width="100vw"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        backgroundColor: '#f5f5f5',
        py: 4,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: isMobile ? '95%' : '60%',
          maxWidth: '800px',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <Typography variant="h2" color="primary" textAlign="center" fontWeight="bold">
          Herramienta de almuerzo
        </Typography>

        <Box>
          <Typography variant="h6" color="primary" fontWeight="500">
            Necesitan almuerzo ({rows.filter((row) => row.needsLunch).length}):
          </Typography>
          <List sx={{ pl: 2, pb: 2 }}>
            {rows
              .filter((row) => row.needsLunch)
              .map((row) => (
                <Typography key={row.username} component="li" sx={{ mb: 1 }}>
                  • <strong>{row.username}</strong>: {row.preferences}
                </Typography>
              ))}
          </List>
          Van a buscar almuerzo hoy: <strong>{randomPeople.map((person) => person.username).join(', ')}</strong>
          <br />
          <Button onClick={() => setShowBankDetails(!showBankDetails)}>
            {showBankDetails ? '▼ Ocultar detalles bancarios' : '▶ Mostrar detalles bancarios'}
          </Button>
          {showBankDetails && (
            <Box>
              <strong>Detalles bancarios:</strong>
              <br />
              {randomPeople.map((person) => {
                const bankAccount = people.find((p) => p.username === person.username)?.bank_account;
                if (!bankAccount) return `Pídele a ${person.username} que suba su cuenta bancaria`;
                return (
                  <Box key={person.username} component="span" display="block" sx={{ border: '1px solid #ccc', p: 1 }}>
                    {bankAccount?.split('\n').map((line, index) => (
                      <Box key={`${person.username}-${index + 1}`} component="span" display="block">
                        {line}
                      </Box>
                    ))}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>

        <Divider />

        <Box>
          <Typography variant="h6" color="primary" fontWeight="500">
            No necesitan almuerzo ({rows.filter((row) => !row.needsLunch).length}):
          </Typography>
          <List sx={{ pl: 2 }}>
            {rows
              .filter((row) => !row.needsLunch)
              .map((row) => (
                <Typography key={row.username} component="li">
                  • {row.username}
                </Typography>
              ))}
          </List>
        </Box>

        <Divider />

        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  checked={needsLunch}
                  onChange={(e) => {
                    setNeedsLunch(e.target.checked);
                    setHasUnsavedChanges(true);
                  }}
                  color="primary"
                />
              }
              label="Necesito almuerzo"
              sx={{ '& .MuiFormControlLabel-label': { fontWeight: 500 } }}
            />
          </Box>

          {/* Add the modal component */}
          <PreferencesModal
            open={preferencesModalOpen}
            onClose={() => setPreferencesModalOpen(false)}
            person={people.find((p) => p.username === currentUser) || null}
            onSave={saveUserDetails}
          />

          {needsLunch && (
            <TextField
              fullWidth
              label="Preferencias de almuerzo"
              value={preferences}
              onChange={(e) => {
                setPreferences(e.target.value);
                setHasUnsavedChanges(true);
              }}
              placeholder="Ej: Vegetariano, Sin gluten, etc."
              variant="outlined"
              size="small"
            />
          )}

          <Button
            variant="contained"
            color="primary"
            disabled={!hasUnsavedChanges}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              saveLunchUpdate();
            }}
          >
            Guardar
          </Button>
        </Box>

        <Divider />

        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setShowPending(!showPending)}
          >
            <Typography variant="h6" color="primary" fontWeight="500" mb={2}>
              Pendientes ({people.filter((person) => !rows.some((row) => row.username === person.username)).length})
            </Typography>
            <ExpandMore
              sx={{
                transform: showPending ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: '0.2s',
                ml: 1,
              }}
            />
          </Box>
          {showPending && (
            <List sx={{ pl: 2 }}>
              {people
                .filter((person) => !rows.some((row) => row.username === person.username))
                .map((person) => (
                  <Typography key={person.username} component="li" sx={{ mb: 1 }}>
                    • <strong>{person.username}</strong> ({person.company})
                  </Typography>
                ))}
            </List>
          )}
        </Box>
        <Divider />
        <SelectUser
          currentUser={currentUser}
          people={people}
          onChange={handleUserChange}
          onEditClick={() => setPreferencesModalOpen(true)}
        />
      </Paper>
    </Box>
  );
};

export default LunchTool;
