import React from 'react';

import { Box, Typography } from '@mui/material';

import { BankOfferRequestAttributes } from '@relif/backend';

const BankOfferStatusBadge = ({ status }: { status: BankOfferRequestAttributes['status'] }) => {
  const parseStatus = () => {
    switch (status) {
      case 'sent_to_bank':
        return 'En evaluación';
      case 'offer_sent':
        return 'Con oferta';
      case 'user_showed_interest':
        return 'Interesado en oferta';
      case 'contact_info_sent_to_bank':
        return 'El banco se contactará';
      case 'rejected_by_bank':
        return 'Sin oferta';
      default:
        return 'En evaluación';
    }
  };
  return (
    <Box sx={{ border: '1px solid', borderColor: 'primary.main', borderRadius: '20px', padding: '6px' }}>
      <Typography variant="body1" color="primary.main" fontSize="12px" lineHeight={1}>
        {parseStatus()}
      </Typography>
    </Box>
  );
};

const BankOfferRequestCard = ({
  bankOfferRequest,
  isLastRow,
}: {
  bankOfferRequest: BankOfferRequestAttributes;
  isLastRow: boolean;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      sx={{ borderBottom: isLastRow ? 'none' : '1px solid #e0e0e0' }}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" color="primary.main" fontSize="16px">
          Compra de cartera
        </Typography>
        <Typography variant="body1" color="text.primary" fontSize="12px">
          {bankOfferRequest.bank}
        </Typography>
      </Box>
      <Box>
        <BankOfferStatusBadge status={bankOfferRequest.status} />
      </Box>
    </Box>
  );
};

BankOfferRequestCard.propTypes = {};

export default BankOfferRequestCard;
