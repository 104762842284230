import { Translation } from './interface';

const englishTranslation: Translation = {
  id: 'en',
  landingPage: {
    header: {
      title: 'Improve your loans and get your finances in order',
      subtitle1: 'We are the platform where employees improve their financial situation.',
      subtitle2: 'Your employer hires us, we help you.',
      knowMoreButton: 'More info',
    },
    navigation: {
      featuresButton: 'Features',
      companiesButton: 'Companies',
      teamButton: 'Our team',
      contactButton: 'Contact us',
      hello: 'Hello',
      dashboardButton: 'Dashboard',
      signUpButton: 'Sign up',
      signInButton: 'Sign in',
      blog: 'Blog',
    },
    features: {
      signUpButton: 'Sign up',
      workCafeFeature1: {
        title: 'Access your credit report',
        description: 'Check how much you owe and if you are behind on your debts',
      },
      feature1: {
        title: 'We clean your credit bureau report',
        description: 'Miguelina reviewed her credit bureau report for free and found a 70% discount to pay her debt',
      },
      feature2: {
        title: 'We improve the conditions of your current loans',
        description:
          'Luis lowered his loan installments from CLP$200,000 to CLP$62,000 without increasing the total amount to be paid',
      },
      feature3: {
        title: 'Organize your money',
        description:
          "Pablo made his budget, now he doesn't struggle at the end of the month and saves for his son's education",
      },
      question: 'What can you do with',
    },
    steps: {
      title: 'All of that in 3 simple steps',
      step1: 'Sign up and complete your data',
      step2: 'We analyze your information',
      step3: 'We will provide you with the best solutions',
    },
    company: {
      title: 'Show this section to your employer',
      subtitle1: 'Give your employees the best benefit they can have,',
      boldSubtitle: 'financial health',
      subtitle2: '. This is what you will get:',
      benefit1: 'More productive employees',
      benefit2: 'Less absenteeism',
      benefit3: 'Higher quality of life in the company',
      benefit4: 'Less employee turnover',
      benefit5: 'Join the companies that are already using Relif',
      title2: 'Some of our clients',
    },
    team: {
      title1: '',
      specialTitle: 'Relif',
      title2: 'Team',
      jorge: {
        job: 'Co-founder & CTO',
        prevJob: [
          {
            place: 'Meta',
            title: 'Software Engineer',
          },
          {
            place: 'PUC',
            title: 'Computer Science Engineer',
          },
        ],
      },
      benja: {
        job: 'Co-founder & CEO',
        prevJob: [
          {
            place: 'London Business School',
            title: 'MBA',
          },
          { place: 'Credicorp Capital', title: 'Investment Banking Associate' },
        ],
      },
      carlos: {
        job: 'Co-founder & COO',
        prevJob: [
          {
            place: 'London Business School',
            title: 'MBA',
          },
          { place: 'Banco Bice', title: 'Wealth Management Advisor' },
        ],
      },
      juan: {
        job: 'Developer',
        prevJob: [
          { place: 'PUC', title: 'Industrial IT Engineer' },
          { place: 'Forcast', title: 'Full-Stack Developer' },
        ],
      },
      tomas: {
        job: 'Developer',
        prevJob: [
          { place: 'PUC', title: 'Computer Science Engineer' },
          { place: 'Celcom', title: 'Software Engineer' },
        ],
      },
      trinidad: {
        job: 'Key Account Manager',
        prevJob: [
          { place: 'Universidad de los Andes', title: 'Services Administrator' },
          { place: 'Promedon', title: 'People Management' },
        ],
      },
      maria_jose: {
        job: 'Chief Commercial Officer (CCO)',
        prevJob: [
          { place: 'PUC', title: 'Commercial Engineer' },
          { place: 'BUK', title: 'Sales Lead' },
        ],
      },
      harold: {
        job: 'Developer',
        prevJob: [
          { place: 'PUC', title: 'Industrial IT Engineer' },
          { place: 'MACH', title: 'Software Engineer' },
        ],
      },
      anibal: {
        job: 'Developer',
        prevJob: [
          { place: 'PUC', title: 'Softare Engineer' },
          { place: 'Aurous', title: 'Software Engineer' },
        ],
      },
    },
    contact: {
      title: 'Do you want to know more?',
      subtitle: 'Give us your email and we will reach out to you as soon as possible.',
      sendEmailButton: 'Send',
      details: {
        title: 'Contact',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        investors: 'We are supported by',
      },
    },
    education: {
      title: 'Learn with Relif',
      tipsTitle: 'Our advice',
      interactiveExamples: [
        {
          title: 'How do I finance my purchase?',
          description: 'This is how much you would spend when buying a refrigerator with different financing methods',
          chartBars: [
            {
              name: 'Cash payment',
              value: 500,
              label: '$500',
            },
            {
              name: 'Loan (2 year term)',
              value: 821,
              label: '$821 (+64%)',
            },
            {
              name: 'Informal loan (2 year term)',
              value: 2000,
              label: '$2,000 (+300%)',
            },
          ],
          tips: [
            'Try to use loans for projects (own house, children studies, etc.), and not for your usual expenses',
            'Even if you are short on money, start saving. (any amount will do)',
          ],
        },
        {
          title: 'How much do I have to pay for my credit card?',
          description:
            'If the amount billed on your card is $100, this is how much you would pay in total, depending on what you pay at the end of the month',
          chartBars: [
            {
              name: 'Paying everything',
              value: 100,
              label: '$100',
            },
            {
              name: 'Paying in 4 installments',
              value: 105,
              label: '$105 (+5%)',
            },
            {
              name: 'Paying the minimum (5%)',
              value: 175,
              label: '$175 (+75%)',
            },
          ],
          tips: [
            'Whenever you can, pay the total amount of the card',
            'If you cannot pay everything, pay the highest amount possible',
            'Avoid paying the minimum',
          ],
        },
        {
          title: 'What happens if I am late with my installments?',
          description: 'How the cost of a $200 installment increases as I fall behind in payment',
          chartBars: [
            {
              name: 'Payment on time',
              value: 200,
              label: '$200',
            },
            {
              name: '15 days late',
              value: 203.5,
              label: '$203.5 (+1.75%)',
            },
            {
              name: '1 month late',
              value: 225,
              label: '$225 (+12.5%)',
            },
            {
              name: '3 months late',
              value: 240,
              label: '$240 (+20%)',
            },
          ],
          tips: [
            'It is generally better to refinance a loan than to live with 1 or 2 overdue installments',
            'Try to avoid being late for more than 3 months, you could end up in a lawsuit and could lose your assets',
          ],
        },
      ],
    },
  },
};

export default englishTranslation;
